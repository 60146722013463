import ky from 'ky';
import TokenService from './tokenService';

export default class ApiCallerHelper {
    
    callGet(urlToCall) {
        var options;
        let token = TokenService.getToken();

        if(token) {
            options = { headers: { authorization: `Bearer ${token}`, }, };
        }
        else {
            options = { };
        }

        return new Promise((resolve, reject) => {
            ky.get(urlToCall, options)
            .json()
            .then(result => resolve(result))
            .catch(err => reject(err));
        });
    }
    callPost(urlToCall, data) {
        var options;
        let token = TokenService.getToken();

        if(token) {
            options = {
                headers: { authorization: `Bearer ${token}` },
                json: data,
            };
        }
        else {
            options = { json: data };
        }

        return new Promise((resolve, reject) => {
            ky.post(urlToCall, options)
            .json()
            .then(result => resolve(result))
            .catch(err => reject(err));
        });
    }
}
