import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    all() {
        const url = this.webApiBaseUrl + "/transport/all";
        return this.apiCallerHelper.callGet(url);
    }

    fromId(id) {
        const url = this.webApiBaseUrl + "/transport/id/" + id;
        return this.apiCallerHelper.callGet(url);
    }
}