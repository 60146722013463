import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    login(username, password) {
        let data = {
            username: username,
            password: password
        }
        const url = this.webApiBaseUrl + "/auth/login";
        return this.apiCallerHelper.callPost(url, data);
    }
}
