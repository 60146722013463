<template>
  <GoogleMap
    api-key="AIzaSyBa7qPkP5JtpvoZNl7VeZIFoioe1_VS-v4"
    :center="{lat: lastEvent.Latitude, lng: lastEvent.Longitude}"
    class="map"
    :zoom="16"
  >
    <!--<Marker :options="{ position: center }" /> -->
    <CustomMarker :options="{
      position: {lat: lastEvent.Latitude, lng: lastEvent.Longitude}, 
      anchorPoint: 'BOTTOM_CENTER'}" 
      :clickable="true" @click="openMapsApp">
      <div style="text-align: center">
        <!--<div style="font-size: 1.125rem">Vuejs Amsterdam</div> -->
        <img src="../assets/panda.png" width="80" height="80" />
      </div>
    </CustomMarker>
  </GoogleMap>
  <div class="under_text">
    <br />
    {{ transport.Name }} <br />
    {{ lastEvent.Address }} <br />
    Ultima posizione: {{ lastEvent.OccurredDate }} <br />
    Temperatura: {{ lastEvent.Temperature }}°
  </div>

</template>

<script>
import { ref, onMounted, onUnmounted} from 'vue';
import { defineComponent } from "vue";
import { GoogleMap, CustomMarker } from "vue3-google-map";
import { services } from '../scripts/services/serviceBuilder'

export default defineComponent({
  components: { GoogleMap, CustomMarker },
  setup() {
    let lastEvent = ref({Id: 0, Latitude: 0, Longitude: 0});
    let transport = ref({});
    let timer;

    const getTransportInfo = () => {
      services.apiCallerTransport.fromId(3)
      .then(response => {
          if(response) {
            transport.value = response.data;
          }
      })
      .catch(error => {
          console.log(error)
      });
    };

    const getTransport3LastEvent = () => {
      services.apiCallerEvent.fromTransportMax(3, 1)
      .then(response => {
          if(response[0] && lastEvent.value.Id != response[0].Id) {
            lastEvent.value = response[0];
          }
      })
      .catch(error => {
          console.log(error)
      });
    };

    const openMapsApp = () => {
      const lat = lastEvent.value.Latitude;
      const lng = lastEvent.value.Longitude;
      const mapUrl = `https://maps.google.com/maps?q=${lat},${lng}`;
      window.open(mapUrl, '_blank');
    };

    onMounted(() => {
      getTransportInfo();
			getTransport3LastEvent();
      timer = setInterval(() => getTransport3LastEvent(), 10 * 1000);
		});

    onUnmounted(() => {
      clearInterval(timer);
    })

    return { 
      transport, 
      lastEvent, 
      openMapsApp };
  }
});
</script>

<style scoped>
  .map{
    /* height: calc(100vh - 16px); */
    height : calc(100vh - 248px);
    width: 100%;
  }

  .under_text {
    color: rgb(0, 0, 0);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

</style>