import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    all() {
        const url = this.webApiBaseUrl + "/event/all";
        return this.apiCallerHelper.callGet(url);
    }

    fromId(id) {
        const url = this.webApiBaseUrl + "/event/id/" + id;
        return this.apiCallerHelper.callGet(url);
    }

    fromTransport(transportId) {
        const url = this.webApiBaseUrl + "/event/transport/" + transportId;
        return this.apiCallerHelper.callGet(url);
    }

    fromTransportMax(transportId, max) {
        const url = this.webApiBaseUrl + "/event/transport/" + transportId + "/max/" + max;
        return this.apiCallerHelper.callGet(url);
    }
}