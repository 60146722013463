<template>
  <div class="login-container">
    <div class="login-content">
      <v-container fluid>
        <v-row justify="center" align="center" class="fill-height">
          <v-col cols="12" sm="8" md="6" lg="4">
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent="login">
                  <v-text-field
                    v-model="username"
                    label="Username"
                    prepend-icon="mdi-account"
                    type="text"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    label="Password"
                    prepend-icon="mdi-lock"
                    type="password"
                    required
                  ></v-text-field>
                  <v-btn
                    type="submit"
                    color="primary"
                    block
                  >
                    Login
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style scoped>
.login-container {
  background-image: url('../assets/worldmap.jpg');
  background-size: cover;
  background-position: center;
  height : calc(100vh - 128px);
  width: 100%;
}

.login-content { }

</style>

<script>
import { services } from '../scripts/services/serviceBuilder'

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    login() {
      services.apiCallerAuth.login(this.username, this.password)
      .then(response => {
        if(response.data && response.data.token)
        {
          services.tokenService.saveToken(response.data.token);
          window.location.href = '/';
        }
        console.log(response.message);
      })
      .catch(error => {
          console.log(error)
      });
    }
  }
};
</script>

<style scoped>
.fill-height {
  min-height: 80vh;
}
</style>
