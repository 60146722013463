
import { createRouter, createWebHistory } from "vue-router"
import LayoutComponent from '../components/LayoutComponent.vue';
import LoginComponent from '../components/LoginComponent.vue';
import MapComponent from '../components/MapComponent.vue';
import { services } from '../scripts/services/serviceBuilder'

const childrenRoutes = [
    {
        path: '/',
        name: 'Map',
        meta: { title: 'Map' },
        component : MapComponent,
        beforeEnter: (to, from, next) => {
            if (services.tokenService.getToken()) {
                next();
            } 
            else {
                next('/login');
            }
        }
    },
    {
        path: '/login',
        name: 'Login',
        meta: { title: 'Login' },
        component: LoginComponent,
        beforeEnter: (to, from, next) => {
            if (services.tokenService.getToken()) {
                next('/');
            } 
            else {
                next();
            }
        }
    }
];

const routes = [
    {
        path: '/',
        component: LayoutComponent,
        children: childrenRoutes
    }
]

const router = createRouter({
    history : createWebHistory(),
    routes : routes
})
    
export default router;
