import jwt_decode from 'jwt-decode';

class TokenService {

    getToken() {
        //console.log('getToken');
        let token = localStorage.getItem('token');

        if(!token)
            return null;

        const decodedToken = jwt_decode(token);
        const expirationDate = new Date(decodedToken.exp * 1000);

        if (expirationDate < new Date()) {
            //console.log('token expired');
            this.removeToken();
            return null;
        }
        else {
            return token;
        }
    }

    saveToken(token) {
        //console.log('saveToken');
        if(token)
            localStorage.setItem('token', token);
    }

    removeToken() {
        localStorage.removeItem('token'); 
    }
}

export default new TokenService();