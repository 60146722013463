import AppSettingsService from "./appSettingsService";
import TokenService from "./tokenService";
import ApiCallerHelper from "./apiCallerHelper";
import ApiCallerAuth from "./apiCallerAuth";
import ApiCallerEvent from "./apiCallerEvent";
import ApiCallerTransport from "./apiCallerTransport";

class Services {

    get appSettingsService() {
        return AppSettingsService;
    }

    get tokenService() {
        return TokenService;
    }

    get apiCallerHelper() {
        return new ApiCallerHelper();
    }

    get apiCallerAuth() {
        return new ApiCallerAuth(this.apiCallerHelper);
    }

    get apiCallerEvent() {
        return new ApiCallerEvent(this.apiCallerHelper);
    }

    get apiCallerTransport() {
        return new ApiCallerTransport(this.apiCallerHelper);
    }
}

export var services = new Services();
