<template>
  <div class="wrapper">
    <v-toolbar dark color="primary">
      <v-toolbar-title class="ml-auto header-title">dove sone le nostre cose?</v-toolbar-title>

      <button v-if="isUserLoggedIn" @click="logout">Logout</button>

    </v-toolbar>
    <main class="content">
      <router-view></router-view>
    </main>
    <v-toolbar dark color="primary" class="footer">
      <v-toolbar-title class="ml-auto footer-title">boh, chi lo sa :)</v-toolbar-title>
    </v-toolbar>
  </div>
</template>

<script>

import { ref, onMounted } from 'vue';
import { defineComponent } from "vue";
import { services } from '../scripts/services/serviceBuilder'

export default defineComponent({
  setup() {
    const isUserLoggedIn = ref(false);

    function checkUserLoggedIn() {
      isUserLoggedIn.value = services.tokenService.getToken() != null;
    }

    function logout() {
      services.tokenService.removeToken()
      window.location.href = '/login';
    }

    onMounted(() => {
      checkUserLoggedIn();
		});

    return {
      isUserLoggedIn,
      logout
    };
  }
});
</script>

<style>

.v-toolbar {
  padding-right: 20px;
}

.header-title {
  font-size: 30px !important;
  padding: 12px;
}

.footer-title {
  font-size: 30px !important;
  text-align: right;
  padding: 12px;
}

.wrapper {
}

.content {
  flex-grow: 1;
}

html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

</style>