<template>
  <router-view height="100%"></router-view>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({

});
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>